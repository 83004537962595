import { useEffect, useState } from 'react';

const App = () => {

  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const response = await fetch('https://circulator-server.herokuapp.com/news/providers');
    setData(await response.json());
  }

  return (
    <div>
      {data.map((d) => <p><a href={`https://circulator-server.herokuapp.com/news/data?type=${d}`}> {d}</a></p>)}
    </div>
  );
}

export default App;
